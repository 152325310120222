/* eslint-disable react-hooks/exhaustive-deps */
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";

import VideoView from './components/VideoView';
import './App.css';
// import { list } from "postcss";


const App = () => {

  const config = {
    protocol: 'wss',
    host: 'red5.ktverkko.fi',
    port: 5443,
    app: 'live',
    rtcConfiguration: {
      iceServers: [{urls: 'stun:stun2.l.google.com:19302'}]
    },
    // apiEndpoint: 'http://localhost:3000/admin/api/streams/'
    apiEndpoint: 'https://winter-stream.com/admin/api/streams/'
  };

  return (
    <Router>
      <div className="App bg-gray-900 flex flex-col items-center justify-center text-gray-300 min-h-full">
        <Switch>
          <Route exact path="/:alias/:streamid" render={(props) => (<VideoView {...props} config={config} />)} />
          <Route exact path="/:alias">
            <Redirect to="/" />
          </Route>
          <Route exact path="/">
            Nothing here...
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
