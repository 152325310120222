import VideoPlayer from './VideoPlayer'
// import { Redirect } from "react-router-dom"
import { motion } from "framer-motion"
import { useState, useEffect } from 'react';

import axios from 'axios';

const VideoView = (props) => {

    const variants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 }
    }

    const [queryStatus, setQueryStatus] = useState('LOADING');
    const [alias, setAlias] = useState();
    const [title, setTitle] = useState("Untitled");
    const [subtitle, setSubtitle] = useState("Subtitle");
    const [streamKey, setStreamKey] = useState();

    useEffect(() => {
        const url = props.config.apiEndpoint+props.match.params.alias+'/'+props.match.params.streamid;
        axios.get(url)
            .then((response) => {
                setAlias(response.data.alias);
                setStreamKey(response.data.key);
                setTitle(response.data.title);
                setSubtitle(response.data.subtitle);
                setQueryStatus('SUCCEEDED');                
            })
            .catch(function (error) {
                setQueryStatus('FAILED');
            });
    })

    if (queryStatus === 'SUCCEEDED') {
        return(
            <motion.div className="w-full" initial="hidden" animate="visible" variants={ variants } transition={{ duration: .5 }} >
                <h2 className="text-2xl">{ title }</h2>
                <p className="text-gray-500">{ subtitle }</p>
                <div className="mt-6 relative">
                    <VideoPlayer config={{streamName: streamKey, ...props.config}} />
                </div>
                <p className="opacity-70 px-3 mt-12 md:mt-4">If you have trouble viewing the video, try <a href={window.location.href} className="underline text-blue-500 hover:text-blue-300">refreshing this page</a>.</p>
            </motion.div>
        )    
    } else if (queryStatus === 'FAILED') {
        return (
            <motion.div className="w-full" initial="hidden" animate="visible" variants={variants} transition={{ duration: .5 }} >
                <h2 className="text-2xl">{ title }</h2>
                <p className="text-gray-500">{ subtitle }</p>
                <div className="mt-6 relative">
                    <VideoPlayer config={{streamName: streamKey, ...props.config}} />
                </div>
                <p className="opacity-70 px-3 mt-12 md:mt-4">If you have trouble viewing the video, try <a href={window.location.href} className="underline text-blue-500 hover:text-blue-300">refreshing this page</a>.</p>
            </motion.div>
            // <Redirect to="/" />
        )
    } else {
        return <div></div>
    }
}

export default VideoView
