
import { useEffect, memo } from 'react';
import Sketch from 'react-p5'
import Io from 'socket.io-client'

const MultiDrawing = () => {

  const socket  = Io('https://winter-stream.com', { path: '/winter/socket.io/' });
  // const socket    = Io('http://localhost:3001', { path: '/winter/socket.io/' });
  const room      = window.location.pathname.split('/').pop();
  let positions   = {};
  let users       = {};
  let opacity     = 255;
  let strokeWidth = 5;
  
  let data,
      lineInterval,
      lineTimeout,
      video,
      width,
      height,
      x = 0,
      y = 0,
      px = 0,
      py = 0,
      easing = 0.3;
    
  const windowResized = (p5) => {
    video  = document.querySelector('#red5pro-subscriber');
    width  = video.clientWidth;
    height = video.clientHeight;
          
    const fullscreenElement = document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement;
      
    if (fullscreenElement) {
      const ratio = 16 / 9;
      width  = window.screen.availWidth;
      height = width / ratio;       
    }

    if (p5)
      p5.resizeCanvas(width, height);
  }

  const setup = (p5, canvasParentRef) => {
    width  = 960;
    height = 410;

    if (video) width  = video.offsetWidth;
    if (video) height = video.offsetHeight;

    p5.createCanvas(width, height).parent(canvasParentRef);
  }

  const draw = p5 => {
    p5.clear();
    
    Object.entries(positions).forEach(([userID, userPositions]) => {
      if (!userPositions)
        return; 
      
      for (let position of userPositions) {
          let color;
          let user = users.filter((user) => user.id === userID);

        if (typeof (user[0]) !== 'undefined') {        
            color = user[0].color;
            color = color.split(',');

            let lastOpacity = userPositions[userPositions.length -1].opacity;
            let lastStroke  = userPositions[userPositions.length -1].stroke;
            
            p5.line(position.x * width, position.y * height, position.px * width, position.py * height);
            p5.stroke(color[0], color[1], color[2], lastOpacity);
            p5.strokeWeight(lastStroke);
          }
      }
    });
  }

  const mouseDragged = (p5) => {
    sendPositions(p5, true);
  }
  
  const mousePressed = (p5) => {
    clearInterval(lineInterval);
    clearTimeout(lineTimeout);
    opacity      = 255;
    strokeWidth  = 5;
    sendPositions(p5);
  }

  const mouseReleased = (p5) => {
    lineTimeout = setTimeout(() => {

      lineInterval = setInterval(() => {
        if (opacity <= 0) {
          clearInterval(lineInterval);
          socket.emit('deletePosition', socket.id);
        }
        
        opacity     -= 51;
        strokeWidth -= 1; 
  
        sendPositions(p5);
      }, 500);

    }, 3000);  
  };
  
  const sendPositions = ((p5, withEasing = false) => {
    if (withEasing) {
      let targetX = p5.mouseX / width;
      let targetY = p5.mouseY / height;
      x += (targetX - x) * easing;
      y += (targetY - y) * easing;
    } else {
      x  = p5.mouseX / width;
      y  = p5.mouseY / height;
      px = p5.mouseX / width;
      py = p5.mouseY / height; 
    }

    data = {
      [socket.id] : {
        x: x,
        y: y,
        px: px,
        py: py,
        opacity: opacity,
        stroke: strokeWidth
      }
    };

    if (withEasing) {
      px = x;
      py = y; 
    }

    socket.emit('updatePosition', data);
  });

  useEffect(() => {
    console.log('hallo')
    
    socket.emit('login', room)
    socket.emit('room', room);
    
    socket.on('positions', data => { positions = data });
    socket.on("users", data => { users = data });
    
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100)
    
  }, [socket])

  return (
    <>
      <Sketch className="absolute top-0 left-0 w-full h-full min-h-full flex items-center z-10" windowResized={windowResized} setup={setup} draw={draw} mousePressed={mousePressed} mouseDragged={mouseDragged} mouseReleased={mouseReleased} />
    </>
  )
}

export default memo(MultiDrawing)